import * as React from 'react';

import SEO from '../../components/seo';
import NavBar from '../../components/navBar';
import Footer from '../../components/Footer';
import TestimonialTwo from '../../components/TestimonialTwo';
import ProductRow from '../../components/ProductRow';

// styles
const pageStyles = {
  color: '#232129',
  // padding: 96,
  fontFamily: '-apple-system, Roboto, sans-serif, serif',
};

const IndexPage = () => {
  return (
    <div style={pageStyles}>
      <SEO
        title="Bcc Text"
        description="Send Bcc texts like you would in an email. Save time - avoid copy/pasting a message to send to each person."
      />
      <NavBar />
      <div className="relative py-16 bg-white overflow-hidden">
        {/* <ContentSquares /> */}
        <div className="relative px-4 sm:px-6 lg:px-8">
          <div className="text-lg max-w-prose mx-auto">
            <h1>
              <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                Bcc Text
              </span>
            </h1>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              <strong>Save time</strong> when sending mass texts to your friends, family, or any group.
            </p>
          </div>
          <div className="mt-6 prose prose-blue prose-lg text-gray-500 mx-auto">
            <p>
              Send Bcc texts like you would in email. Instead of creating a new message for each person, pasting your
              message, then hitting send -- you can, enter all the contacts and message at once. You will have to
              manually hit send for each person but you will save time this way.
            </p>
            <p>
              This app is great for anyone but especially if you're in jobs like real estate or a tour guide -- where
              you need to share the same information to multiple people but without a group chat.
            </p>
            <p>Ideally this app wouldn't exist. Hopefully Apple builds this functionality into their Messages app.</p>
          </div>
        </div>
      </div>
      <TestimonialTwo />
      <ProductRow products={products} />
      <Footer />
    </div>
  );
};

export default IndexPage;

const products = [
  {
    id: 1,
    href: 'https://apps.apple.com/us/app/bcc-text/id1613103349',
    imageSrc:
      'https://is1-ssl.mzstatic.com/image/thumb/PurpleSource116/v4/3d/51/83/3d518330-30e3-b3b8-f900-689232b07658/4e737a79-6947-447e-a562-f512a2da9762_2.png/920x0w.webp',
    eventName: 'image_1_bcc_text_clicked',
  },
  {
    id: 2,
    href: 'https://apps.apple.com/us/app/bcc-text/id1613103349',
    imageSrc:
      'https://is5-ssl.mzstatic.com/image/thumb/PurpleSource116/v4/e3/fd/a7/e3fda780-5c42-3f43-df1d-968eb1ad265c/8d60a99d-7c27-4eae-96f7-da70fc6e1194_6.png/920x0w.webp',
    eventName: 'image_2_bcc_text_clicked',
  },
  {
    id: 3,
    href: 'https://apps.apple.com/us/app/bcc-text/id1613103349',
    imageSrc:
      'https://is3-ssl.mzstatic.com/image/thumb/PurpleSource116/v4/43/f6/14/43f6146b-707f-fa1e-9200-1014d687b6bc/82054f4f-2770-4521-a65c-256cd9ac61bc_1.png/920x0w.webp',
    eventName: 'image_3_bcc_text_clicked',
  },
  // {
  //   id: 4,
  //   href: "https://apps.apple.com/us/app/bcc-text/id1613103349",
  //   imageSrc:
  //     "https://is1-ssl.mzstatic.com/image/thumb/PurpleSource126/v4/4a/5c/66/4a5c6651-c621-8f44-8ff5-1599c886f733/abdf8b62-b2a5-4d6d-b1ef-a28c5e7600b8_3.png/920x0w.webp",
  // },
];
